import { storesService } from "@/services";
import { getLocalDataBase } from "@/_helpers";
import Dexie from "dexie";

export const stores = {
  namespaced: true,
  state: {
    salesPricesRange: [],
  },
  actions: {
    // eslint-disable-next-line
    // setSalesPricesRangesConfiguration({ commit, dispatch }, { forceUpdate, autoSync }) {
    //   let startDate = new Date(Date.now()).toLocaleString("pt");
    //   //Initialize the value of the synccounter which will filter the information will get
    //   let synccounter = 1;
    //   //Open database and make it start functioning.
    //   let db = getLocalDataBase();
    //   db.open();
    //
    //   //Use Dexie.spawn() or Dexie.async() to enable a synchronous-like programming style.
    //   var spawn = Dexie.spawn;
    //   // Interact With Database
    //   spawn(function* () {
    //     // Let's query the db
    //     //Getting the last information stored on the Entities table, to the Payment Method's Entity
    //     if (forceUpdate === 0) {
    //       var entityInfo = yield db.entities.where("entity").equals("store").toArray();
    //       if (entityInfo.length > 0) {
    //         synccounter = entityInfo.map((f) => f.synccounter)[0];
    //       }
    //     }
    //     //The "put" adds a new object or updates an existing object.
    //     //  I've chosen to use it instead of the "update", because the update only updates an existing object.
    //     //  Therefore, using the "update" it would have been necessary to verify if the "update" returned 1 and if not
    //     //  performing an insert.
    //     db.entities.put({
    //       entity: "store",
    //       startDate: new Date().toISOString(),
    //       endDate: null,
    //       nrSyncEntities: null,
    //       synccounter: synccounter,
    //     });
    //
    //     //Accessing the backend API to get all the Payment Methods which match with the identified synccounter
    //     let result = yield storesService.getSalesPricesRange(synccounter);
    //
    //     //Iterating over the retrieved Sales Prices Ranges:
    //     //1 - building the dictionary which will add the Sales Prices Range to the database
    //     //2 - obtaining the maximum synccounter of the retrieved Sales Prices Range
    //     let maxSynccounter = 0;
    //     let bulkSalesPricesRange = [];
    //     for (var i = 0; i < result.length; i++) {
    //       bulkSalesPricesRange.push({
    //         code: result[i]["Code"],
    //         description: result[i]["Description"],
    //         status: result[i]["Status"],
    //         synccounter: result[i]["synccounter"],
    //       });
    //
    //       if (result[i].synccounter > maxSynccounter) {
    //         maxSynccounter = result[i].synccounter;
    //       }
    //     }
    //
    //     //1 - Adding all given objects to the store.
    //     //2 - updating the Entities table, setting the new synccounter for the Entity Store and setting the new
    //     // last sync date
    //     if (bulkSalesPricesRange.length > 0) {
    //       db.salesPricesRanges
    //         //  If an object with the same primary key already exists, it will be replaced with the given object.
    //         //  If it does not exist, it will be added.
    //         .bulkPut(bulkSalesPricesRange)
    //         .then(function () {
    //           db.entities.put({
    //             entity: "store",
    //             startDate: startDate,
    //             endDate: new Date(Date.now()).toLocaleString("pt"),
    //             nrSyncEntities: bulkSalesPricesRange.length,
    //             synccounter: maxSynccounter,
    //           });
    //         })
    //         // eslint-disable-next-line
    //         .catch(Dexie.BulkError, function (e) {
    //           // Explicitly catching the bulkAdd() operation makes those successful
    //           // additions commit despite that there were errors.
    //         });
    //     } else {
    //       db.entities.put({
    //         entity: "store",
    //         startDate: startDate,
    //         endDate: new Date(Date.now()).toLocaleString("pt"),
    //         nrSyncEntities: 0,
    //         synccounter: synccounter,
    //       });
    //     }
    //
    //     //Setting the synchronization to run 3 minutes after the synchronization is finished
    //     if (autoSync === 1) {
    //       setInterval(function () {
    //         let autoSync = 0;
    //         let forceUpdate = 0;
    //         dispatch("setZonesConfiguration", { forceUpdate, autoSync });
    //       }, 180000);
    //     }
    //
    //     // eslint-disable-next-line
    //   }).catch(function (err) {
    //     // Catch any error event or exception
    //     console.error(err.stack || err);
    //   });
    // },
    // eslint-disable-next-line
    setZonesConfiguration({ commit, dispatch }, { forceUpdate }) {
      // Initialize variables
      let startDate = new Date().toLocaleString("pt");
      let synccounter = 1;
      let nrSyncEntities = 0;

      //Open database and make it start functioning.
      let db = getLocalDataBase();
      db.open();

      //Use Dexie.spawn() or Dexie.async() to enable a synchronous-like programming style.
      var spawn = Dexie.spawn;
      // Interact With Database
      spawn(function* () {
        // Let's query the db
        // Getting the last information stored on the Entities table, to the Payment Method's Entity
        if (forceUpdate === 0) {
          const entityInfo = yield db.entities.where("entity").equals("store").toArray();
          if (entityInfo.length > 0) {
            synccounter = entityInfo.map((f) => f.synccounter)[0];
          }
        }

        //The "put" adds a new object or updates an existing object.
        //  I've chosen to use it instead of the "update", because the update only updates an existing object.
        //  Therefore, using the "update" it would have been necessary to verify if the "update" returned 1 and if not
        //  performing an insert.
        db.entities.put({
          entity: "store",
          startDate: new Date().toISOString(),
          endDate: null,
          nrSyncEntities: null,
          synccounter: synccounter,
        });

        //Iterating over the retrieved Sales Prices Ranges:
        //1 - building the dictionary which will add the Sales Prices Range to the database
        //2 - obtaining the maximum synccounter of the retrieved Sales Prices Range
        let page = 1;
        let maxSynccounter = synccounter || 0;
        let bulkZones = [];
        let response;
        try {
          do {
            bulkZones = [];

            // Accessing the backend API to get all the Zones which match with the identified synccounter
            response = yield storesService.getZonesPage(page);
            const result = response.results;

            for (let i = 0; i < result.length; i++) {
              bulkZones.push({
                code: result[i]["ZoneId"],
                description: result[i]["ZoneDescription"],
                status: result[i]["Status"],
                salesPriceRange: result[i]["SalesPriceIndex"],
                docType: result[i]["DocType"],
                data: result[i],
                synccounter: result[i]["synccounter"],
              });

              if (result[i].synccounter > maxSynccounter) {
                maxSynccounter = result[i].synccounter;
              }
            }

            //1 - Adding all given objects to the store.
            //2 - updating the Entities table, setting the new synccounter for the Entity Store and setting the new
            // last sync date
            if (bulkZones.length > 0) {
              nrSyncEntities += bulkZones.length;
              db.zones
                //  If an object with the same primary key already exists, it will be replaced with the given object.
                //  If it does not exist, it will be added.
                .bulkPut(bulkZones)
                .then(function () {
                  db.entities.put({
                    entity: "store",
                    startDate: startDate,
                    endDate: new Date().toLocaleString("pt"),
                    nrSyncEntities: nrSyncEntities,
                    synccounter: synccounter,
                    page: page,
                  });
                })
                // eslint-disable-next-line
                .catch(Dexie.BulkError, function (e) {
                  // Explicitly catching the bulkAdd() operation makes those successful
                  // additions commit despite that there were errors.
                });
              page++;
            }
          } while (response.next && page < 2000);

          // Only save the maxsynccounter after all the pages are fetched
          db.entities.put({
            entity: "store",
            startDate: startDate,
            endDate: new Date().toLocaleString("pt"),
            nrSyncEntities: nrSyncEntities,
            synccounter: maxSynccounter,
          });
        } catch (err) {
          // Retry logic failed
          db.entities.put({
            entity: "store",
            startDate: startDate,
            endDate: new Date().toLocaleString("pt"),
            nrSyncEntities: nrSyncEntities,
            synccounter: synccounter,
          });
        }

        // eslint-disable-next-line
      }).catch(function (err) {
        // Catch any error event or exception
        console.error(err.stack || err);
      });
    },
  },
  getters: {
    // getSalesPricesRange: () => (code) => {
    //   let db = getLocalDataBase();
    //
    //   return db.zones.where("code").equals(code).toArray();
    // },
    getZones: () => (code) => {
      let db = getLocalDataBase();
      return db.zones.where("code").equals(code).toArray();
    },

    getZoneDocType: () => async (code) => {
      let db = getLocalDataBase();
      const zones = await db.zones.where("code").equals(code).toArray();
      return zones.length === 1 ? zones[0].docType : null;
    },
    // async getAllSalesPricesRanges() {
    //   let db = getLocalDataBase();
    //
    //   //1st filtering the active Sales Prices
    //   let salesPricesRanges = db.salesPricesRanges.where("status").equals(1);
    //   salesPricesRanges = await salesPricesRanges.toArray();
    //
    //   return salesPricesRanges;
    // },
    async getAllZones() {
      let db = getLocalDataBase();

      //1st filtering the active Sales Prices
      let zones = db.zones.where("status").equals(1);
      zones = await zones.toArray();
      return zones;
    },
  },
};
