import { entitiesService } from "@/services";

export const docTypesServices = {
  getPage,
  getMaxSynccounter,
};

async function getPage(synccounter, page) {
  return entitiesService.getPage("pos/doctypes/", synccounter, page);
}

async function getMaxSynccounter(synccounter) {
  return entitiesService.getMaxSynccounter("pos/doctypes/synccounter/max/", synccounter);
}
