import { itemsService } from "@/services";
import { getLocalDataBase } from "@/_helpers";
import Dexie from "dexie";
import Vue from "vue";
import { configs } from "@/store/configs.module";

export const items = {
  namespaced: true,
  state: {
    items: [],
  },
  actions: {
    // eslint-disable-next-line
    setConfiguration({ commit, dispatch }, { forceUpdate }) {
      // Initialize variables
      let startDate = new Date().toLocaleString("pt");
      let synccounter = 1;
      let nrSyncEntities = 0;

      // The following variables will be used to continue an ongoing synchronization
      let savedMaxsynccounter;
      let actualMaxsynccounter;
      let page = 1;

      // Open database and make it start functioning.
      let db = getLocalDataBase();
      db.open();

      // Use Dexie.spawn() or Dexie.async() to enable a synchronous-like programming style.
      var spawn = Dexie.spawn;
      // Interact With Database
      spawn(function* () {
        // Let's query the db
        // Getting the last information stored on the Entities table, to the Item's Entity
        if (forceUpdate === 0) {
          const entityInfo = yield db.entities.where("entity").equals("item").toArray();
          let savedPage;
          if (entityInfo.length > 0) {
            synccounter = entityInfo.map((f) => f.synccounter)[0];
            savedMaxsynccounter = entityInfo.map((f) => f.maxsynccounter)[0];
            savedPage = entityInfo.map((f) => f.page)[0];
          }

          // If there is an existing synchronization not finished
          if (savedPage) {
            // Fetch the actual maxsynccounter
            actualMaxsynccounter = yield itemsService.getMaxSynccounter(synccounter);

            // If the actual max synccounter is different from the one when we started the synchronization
            // We need to restart the process otherwise we may lose changes
            if (savedMaxsynccounter && actualMaxsynccounter && savedMaxsynccounter === actualMaxsynccounter) {
              page = savedPage;
            }
          }
        }

        //The "put" adds a new object or updates an existing object.
        //  I've chosen to use it instead of the "update", because the update only updates an existing object.
        //  Therefore, using the "update" it would have been necessary to verify if the "update" returned 1 and if not
        //  performing an insert.
        db.entities.put({
          entity: "item",
          startDate: startDate,
          endDate: null,
          nrSyncEntities: null,
          synccounter: synccounter,
        });

        let maxSynccounter = synccounter || 0;
        let bulkItems = [];
        let bulkItemsSearch = [];
        try {
          do {
            bulkItems = [];
            bulkItemsSearch = [];

            // Accessing the backend API to get all the Items which match with the identified synccounter
            const result = yield itemsService.getPage(synccounter, page);
            for (let i = 0; i < result.length; i++) {
              bulkItems.push({
                id: result[i]["id"],
                code: result[i]["code"],
                barcode: result[i]["barcode"],
                mainDescription: result[i]["main_description"],
                description: result[i]["description"],
                family: result[i]["family"],
                status: result[i]["status"],
                synccounter: result[i]["synccounter"],
                data: result[i],
              });
              bulkItemsSearch.push({
                id: result[i]["id"],
                code: result[i]["code"],
                barcode: result[i]["barcode"],
                mainDescription: result[i]["main_description"],
                description: result[i]["description"],
                parentItem: null,
                status: result[i]["status"],
              });

              if (result[i].variantsDetails.length > 0) {
                for (let j = 0; j < result[i].variantsDetails.length; j++) {
                  bulkItemsSearch.push({
                    id: result[i].variantsDetails[j]["id"],
                    code: result[i].variantsDetails[j]["code"],
                    barcode: result[i].variantsDetails[j]["barcode"],
                    description: result[i].variantsDetails[j]["description"],
                    parentItem: result[i]["id"],
                    status: result[i]["status"],
                  });
                }
              }

              if (result[i].synccounter > maxSynccounter) {
                maxSynccounter = result[i].synccounter;
              }
            }

            // 1 - Adding all given objects to the store.
            // 2 - updating the Entities table, setting the new synccounter for the Entity Items and setting the new
            // last sync date
            // 3 - Adding all the Items (parents and variants) to the itemsSearchTerms table
            if (bulkItems.length > 0) {
              // To avoid unnecessary requests, it may not be calculated yet
              if (!actualMaxsynccounter) {
                actualMaxsynccounter = yield itemsService.getMaxSynccounter(synccounter);
              }

              nrSyncEntities += bulkItems.length;
              yield db.items
                // If an object with the same primary key already exists, it will be replaced with the given object.
                // If it does not exist, it will be added.
                .bulkPut(bulkItems)
                .then(function () {
                  db.entities.put({
                    entity: "item",
                    startDate: startDate,
                    endDate: new Date().toLocaleString("pt"),
                    nrSyncEntities: nrSyncEntities,
                    synccounter: synccounter,
                    maxsynccounter: actualMaxsynccounter,
                    page: page,
                  });
                })
                // eslint-disable-next-line
                .catch(Dexie.BulkError, function (e) {
                  // Explicitly catching the bulkAdd() operation makes those successful
                  // additions commit despite that there were errors.
                });

              //Dividing the insert on the Items' Search table in smaller "groups".
              //This refactor was identified due to the enormous amount of data that exists on the SBS:
              //  instead of inserting 16K at the same time, will have various inserts of 10k
              db.itemsSearchTerms
                //  If an object with the same primary key already exists, it will be replaced with the given object.
                //  If it does not exist, it will be added.
                .bulkPut(bulkItemsSearch)
                // eslint-disable-next-line
                .catch(Dexie.BulkError, function (e) {
                  // Explicitly catching the bulkAdd() operation makes those successful
                  // additions commit despite that there were errors.
                  Vue.$log.error("Erro a inserir artigos na Tabela ItemsSearchTerms:" + e);
                });
              page++;
            }
          } while (bulkItems.length > 0 && page < 2000);

          // Only save the maxsynccounter after all the pages are fetched
          db.entities.put({
            entity: "item",
            startDate: startDate,
            endDate: new Date().toLocaleString("pt"),
            nrSyncEntities: nrSyncEntities,
            synccounter: maxSynccounter,
          });
        } catch (err) {
          // To avoid unnecessary requests, it may not be calculated yet
          if (!actualMaxsynccounter) actualMaxsynccounter = yield itemsService.getMaxSynccounter(synccounter);

          // Retry logic failed
          db.entities.put({
            entity: "item",
            startDate: startDate,
            endDate: new Date().toLocaleString("pt"),
            nrSyncEntities: nrSyncEntities,
            synccounter: synccounter,
            maxsynccounter: actualMaxsynccounter,
            page: page,
          });
        }

        // eslint-disable-next-line
      }).catch(function (err) {
        // Catch any error event or exception
        Vue.$log.error(err.stack + " " + err);
      });
    },
    //Função disponibilizada de forma temporária
    // // eslint-disable-next-line
    // async setItemsSearch({ commit, dispatch }) {
    //   let db = getLocalDataBase();
    //   db.open();
    //
    //   let bulkItemsSearch = [];
    //
    //   const itemsSearchTerms = await db.itemsSearchTerms.toArray();
    //   const items = await db.items.toArray();
    //
    //   console.log("itemsSearchTerms", itemsSearchTerms.length);
    //   if (itemsSearchTerms.length === 0) {
    //     for (var i = 0; i < items.length; i++) {
    //       console.log("setItemsSearch 3");
    //       bulkItemsSearch.push({
    //         id: items[i]["id"],
    //         code: items[i]["code"],
    //         barcode: items[i]["barcode"],
    //         description: items[i]["description"],
    //         parentItem: null,
    //       });
    //
    //       // console.log("--------", items[i].data.variantsDetails);
    //       if (
    //         items[i].data.variantsDetails &&
    //         items[i].data.variantsDetails.length > 0
    //       ) {
    //         for (var j = 0; j < items[i].data.variantsDetails.length; j++) {
    //           bulkItemsSearch.push({
    //             id: items[i].data.variantsDetails[j]["id"],
    //             code: items[i].data.variantsDetails[j]["code"],
    //             barcode: items[i].data.variantsDetails[j]["barcode"],
    //             description: items[i].data.variantsDetails[j]["description"],
    //             parentItem: items[i]["id"],
    //           });
    //         }
    //       }
    //     }
    //
    //     db.itemsSearchTerms
    //       //  If an object with the same primary key already exists, it will be replaced with the given object.
    //       //  If it does not exist, it will be added.
    //       .bulkPut(bulkItemsSearch)
    //       // eslint-disable-next-line
    //       .catch(Dexie.BulkError, function (e) {
    //         // Explicitly catching the bulkAdd() operation makes those successful
    //         // additions commit despite that there were errors.
    //       });
    //   }
    // },
    getAll: () => {
      let db = getLocalDataBase();

      return db.items.where("status").equals(1).toArray();
    },
    get(params, id) {
      let db = getLocalDataBase();

      return db.items.where("id").equals(id).toArray();
    },
    getItemActive(params, id) {
      let db = getLocalDataBase();

      return db.items.where({ id: id, status: 1 }).toArray();
    },
    getLookUpSearchTerms(params, id) {
      let db = getLocalDataBase();

      return db.itemsSearchTerms.where("id").equals(id).toArray();
    },
    getFamilyItems(params, { familyId, offset, limit }) {
      let db = getLocalDataBase();
      let items = db.items.where({ family: familyId, status: 1 });
      if (offset !== undefined && limit !== undefined) items = items.offset(offset).limit(limit);

      return items.toArray();
    },
    async getItemsByExactSearch(params, { searchTerm, offset, limit }) {
      let db = getLocalDataBase();

      let resultItems = [];

      // return db.items
      //   .filter(function (v) {
      //     return (
      //       v.description
      //         .toLowerCase()
      //         .normalize("NFD")
      //         .replace(/[\u0300-\u036f]/g, "")
      //         .indexOf(searchTerm.toLowerCase()) >= 0 ||
      //       v.code === searchTerm ||
      //       (!!v.barcode && v.barcode === searchTerm)
      //     );
      //   })
      //   .toArray();
      //
      // return db.itemsSearchTerms
      //   .filter(function (v) {
      //     return (
      //       v.description
      //         .toLowerCase()
      //         .normalize("NFD")
      //         .replace(/[\u0300-\u036f]/g, "")
      //         .indexOf(searchTerm.toLowerCase()) >= 0 ||
      //       v.code === searchTerm ||
      //       (!!v.barcode && v.barcode === searchTerm)
      //     );
      //   })
      //   .toArray();

      // @modified ana.castro 2025.03.17 SAFO-112
      // Added the line: and((item) => item.status === 1);
      resultItems = db.itemsSearchTerms
        .where("barcode")
        .equalsIgnoreCase(searchTerm)
        .or("code")
        .equalsIgnoreCase(searchTerm)
        .and((item) => item.status === 1);
      // The Items' Search is returning the Items which are inactive.
      // To correct this situation we've tried to apply and AND condition.
      // However, filtering the Items at this point, results in a slower response, since due to
      // returning 0 zeros at this point, the procedure continues to the next filter which is considerable slower.
      // To conclude, to overcome this problem, we've decided to apply a validation on the getSearchResults() function
      // of the CatalogSearchResults.
      // .and((item) => item.status === 1 || item.status === undefined);

      let numberResults = await resultItems.count();

      //If a exact match of Barcode and Code are not found, then search is performed on the Description field
      if (numberResults === 0) {
        resultItems = db.itemsSearchTerms.filter(function (v) {
          return (
            // variants don't have main_description
            (configs.state.itemsDescription === "1" || !v.main_description ? v.description : v.main_description)
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .indexOf(searchTerm.toLowerCase()) >= 0
          );
        });
      }

      if (offset !== undefined && limit !== undefined) resultItems = resultItems.offset(offset).limit(limit);

      return resultItems.toArray();
    },
  },
  getters: {},
};
