import { tenantsService } from "@/services";
import { store } from "../store";
import { getLocalDataBase } from "@/_helpers";
import Dexie from "dexie";

export const tenants = {
  namespaced: true,
  actions: {
    // eslint-disable-next-line
    async setConfiguration({ commit, dispatch }, { forceUpdate, autoSync }) {
      let startDate = new Date().toLocaleString("pt");
      let db = getLocalDataBase();
      db.open();

      const spawn = Dexie.spawn;
      spawn(function* () {
        let serverSynccounter = yield tenantsService.getSynccounter();
        let localSynccounter = 0;

        const coreInfo = yield db.entities.where("entity").equals("core").toArray();
        if (coreInfo.length === 1) localSynccounter = coreInfo[0].synccounter;

        if (serverSynccounter > localSynccounter) {
          updateDatabase(forceUpdate);
          db.entities.put({
            entity: "core",
            startDate: startDate,
            endDate: null,
            nrSyncEntities: null,
            synccounter: serverSynccounter,
          });
        }

        //Setting the synchronization to run 3 minutes after the synchronization is finished
        if (autoSync === 1) {
          setTimeout(function () {
            let autoSync = 0;
            let forceUpdate = 0;
            dispatch("setConfiguration", { forceUpdate, autoSync });
          }, 180000);
        }
      }).catch(function (err) {
        console.error(err.stack || err);
      });
    },
  },
};

function updateDatabase(forceUpdate) {
  store.dispatch("families/setConfiguration", { forceUpdate });
  store.dispatch("items/setConfiguration", { forceUpdate });
  store.dispatch("paymentTypes/setConfiguration", { forceUpdate });
  store.dispatch("salesmen/setConfiguration", { forceUpdate });
  store.dispatch("salesmen/setConfigurationPermissionGroup", { forceUpdate });
  store.dispatch("cashDrawers/setConfiguration", { forceUpdate });
  store.dispatch("countries/setConfiguration", { forceUpdate });
  // ref_object.$store.dispatch("stores/setSalesPricesRangesConfiguration", { forceUpdate });
  store.dispatch("stores/setZonesConfiguration", { forceUpdate });
  store.dispatch("modelExtension/setConfiguration", { forceUpdate });
  store.dispatch("docTypes/setConfiguration", { forceUpdate });
}
