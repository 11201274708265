import { entitiesService } from "@/services";

export const countriesServices = {
  getPage,
  getMaxSynccounter,
};

async function getPage(synccounter, page) {
  return entitiesService.getPage("pos/countries/", synccounter, page, false);
}

async function getMaxSynccounter(synccounter) {
  return entitiesService.getMaxSynccounter("pos/countries/synccounter/max/", synccounter, false);
}
