import { apiFetch, getApiBaseStore, getApiParamsString } from "@/_helpers";
import Vue from "vue";

export const entitiesService = {
  getMaxSynccounter,
  getPage,
};

/**
 * Function to fetch the max synccounter of an entity
 * @param path - API endpoint
 * @param synccounter
 * @param includeStore - Send the store in the request
 * @returns {Promise<*>}
 */
async function getMaxSynccounter(path, synccounter, includeStore = true) {
  const params = {};

  if (synccounter) params["synccounter"] = synccounter;
  if (includeStore) params["store"] = getApiBaseStore();

  let fetchPath = `${path}?${getApiParamsString(params)}`;
  try {
    const response = await apiFetch(fetchPath);
    return response["synccounter"];
  } catch (error) {
    Vue.$log.debug(`Failed to get maxsynccounter on path ${path}`, error);
  }
}

/**
 * Centralized function to fetch an API page with retry logic
 * @added pedro.magalhaes SABO-107 2024.10.14
 * @param synccounter - synccounter to append to the request
 * @param page - Page number to fetch
 * @param path - API endpoint path
 * @param {boolean} includeStore - Whether to include the store parameter in the request
 * @returns {Promise<*|*[]>}.
 */
async function getPage(path, synccounter, page, includeStore = true) {
  const params = {};

  if (synccounter) params["synccounter"] = synccounter;
  if (includeStore) params["store"] = getApiBaseStore();

  let fetchPath = `${path}?${getApiParamsString(params)}`;
  const MAX_RETRIES = 4;
  for (let attempt = 1; attempt < MAX_RETRIES + 1; attempt++) {
    try {
      return await apiFetch(fetchPath + "&page=" + page);
    } catch (error) {
      Vue.$log.debug(`Failed to get ${path} - Attempt ${attempt}`, error);

      // On the last attempt, timeout is unnecessary as the loop will break
      if (attempt < MAX_RETRIES) {
        await new Promise((resolve) => setTimeout(resolve, 2 ** attempt * 1000));
      }
    }
  }
  throw new Error();
}
