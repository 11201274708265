import { apiPost } from "@/_helpers";
import router from "@/router";
import { entitiesService } from "@/services";

export const salesmenService = {
  getPage,
  getMaxSynccounter,
  getSalesmenLevelsPage,
  getSalesmenLevelsMaxSynccounter,
  setSalesmenSessions,
};

async function getPage(synccounter, page) {
  return entitiesService.getPage("pos/salesmans/", synccounter, page);
}

async function getMaxSynccounter(synccounter) {
  return entitiesService.getMaxSynccounter("pos/salesmans/synccounter/max/", synccounter);
}

async function getSalesmenLevelsPage(synccounter, page) {
  return entitiesService.getPage("pos/salesmanlevels/", synccounter, page, false);
}

async function getSalesmenLevelsMaxSynccounter(synccounter) {
  return entitiesService.getMaxSynccounter("pos/salesmanlevels/synccounter/max/", synccounter, false);
}

function setSalesmenSessions(attributes) {
  let submitData = {};

  submitData = {
    StoreId: router.currentRoute.params.store,
    SalesmanId: attributes.salesman,
    StartTime: attributes.startTime,
    EndTime: attributes.endTime,
  };

  return apiPost("pos/salesmansessions/", submitData);
}
