import { apiFetch, apiPost, getApiBaseStore, getApiParamsString } from "@/_helpers";
import router from "@/router";
import { entitiesService } from "@/services";

export const cashDrawersService = {
  getPage,
  getMaxSynccounter,
  get,
  getOpen,
  getAllCashDrawerCloseMovements,
  setCashDrawerMovement,
};

async function getPage(synccounter, page) {
  return entitiesService.getPage("pos/cashdrawers/", synccounter, page);
}

async function getMaxSynccounter(synccounter) {
  return entitiesService.getMaxSynccounter("pos/cashdrawers/synccounter/max/", synccounter);
}

function get(pk) {
  return apiFetch("pos/cashdrawers/" + pk + "/?store=" + getApiBaseStore());
}

function getOpen() {
  // console.log('getOpen', 'Router Store Value =, router.currentRoute.params.store)
  return apiFetch("pos/cashdrawers/?store=" + getApiBaseStore() + "&isOpen=1");
}

async function getAllCashDrawerCloseMovements(params) {
  let cashDrawerCloseMovements = [];

  let result = [];
  let i = 1;
  let fetchPath = "pos/cashdrawersmovements/?store=" + getApiBaseStore();
  fetchPath += "&" + getApiParamsString(params);

  do {
    result = await apiFetch(fetchPath + "&page=" + i);
    cashDrawerCloseMovements = cashDrawerCloseMovements.concat(result.results);
    i += 1;
  } while (result.next && i < 2000);
  return cashDrawerCloseMovements;
}

function setCashDrawerMovement(attributes) {
  let submitData = {};

  let paymentMethods = attributes.paymentMethods;
  let tenders = [];
  for (var j = 0; j < paymentMethods.length; j++) {
    if (paymentMethods[j]["method"] && paymentMethods[j].value) {
      let saleTender = {
        TenderId: paymentMethods[j]["method"],
        Amount: paymentMethods[j].value,
      };

      tenders.push(saleTender);
    }
  }
  submitData = {
    TransDocument: attributes.documentType,
    TillId: attributes.tillId,
    IncludesPattyCash: attributes.includesPattyCash,
    PattyCash: attributes.pattyCash,
    PattyCashFollowingDay: attributes.pattyCashFollowingDay,
    SalesmanId: attributes.salesman,
    SystemEntryDate: new Date(Date.now()),
    TotalAmount: attributes.totalValue,
    StoreId: router.currentRoute.params.store,

    TenderTransactionDetails: tenders,
  };

  return apiPost("pos/cashdrawersmovements/", submitData);
}
