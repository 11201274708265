import { apiFetch } from "@/_helpers";
import { entitiesService } from "@/services";

export const itemsService = {
  getPage,
  getMaxSynccounter,
  getStock,
};

async function getPage(synccounter, page) {
  return entitiesService.getPage("pos/simple/parentitems/", synccounter, page);
}

async function getMaxSynccounter(synccounter) {
  return entitiesService.getMaxSynccounter("pos/itemsfull/synccounter/max/", synccounter);
}

async function getStock(itemCode) {
  let itemsStock = [];

  let result = [];
  let i = 1;
  let fetchPath = "pos/stocks/?ItemId=" + itemCode;

  do {
    result = await apiFetch(fetchPath + "&page=" + i);
    itemsStock = itemsStock.concat(result.results);
    i += 1;
  } while (result.next && i < 2000);

  return itemsStock;
}
