import { entitiesService } from "@/services";

export const familiesService = {
  getPage,
  getMaxSynccounter,
};

async function getPage(synccounter, page) {
  return entitiesService.getPage("pos/families/", synccounter, page);
}

async function getMaxSynccounter(synccounter) {
  return entitiesService.getMaxSynccounter("pos/families/synccounter/max/", synccounter);
}
