import { apiFetch, getApiBaseStore } from "@/_helpers";
import { entitiesService } from "@/services";

export const paymentTypesService = {
  getPage,
  getMaxSynccounter,
  validatePaymentMethodAuto,
};

async function getPage(synccounter, page) {
  return entitiesService.getPage("pos/paymenttypes/", synccounter, page);
}

async function getMaxSynccounter(synccounter) {
  return entitiesService.getMaxSynccounter("pos/paymenttypes/synccounter/max/", synccounter);
}

function validatePaymentMethodAuto(attributes) {
  let str = Object.entries(attributes)
    .map(([key, val]) => `${key}=${val}`)
    .join("&");
  return apiFetch("pos/validatepaymentypeauto/?" + str + "&Store=" + getApiBaseStore());
}
