import { apiFetch, getApiBaseStore } from "@/_helpers";
import { entitiesService } from "@/services";

export const storesService = {
  getSalesPricesRange,
  getZonesPage,
};

async function getSalesPricesRange() {
  let salesPricesRanges = [];

  let result = [];
  let i = 1;
  let fetchPath = "pos/salespricesranges/?store=" + getApiBaseStore();

  do {
    result = await apiFetch(fetchPath + "&page=" + i);
    salesPricesRanges = salesPricesRanges.concat(result);
    i += 1;
  } while (result.next && i < 2000);

  return salesPricesRanges;
}

async function getZonesPage(page) {
  return entitiesService.getPage("pos/zones/", null, page);
}
