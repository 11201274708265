import { paymentTypesService } from "@/services";
import { getLocalDataBase } from "@/_helpers";
import Dexie from "dexie";

export const paymentTypes = {
  namespaced: true,
  state: {
    paymentMethods: {},
  },
  actions: {
    // eslint-disable-next-line
    setConfiguration({ commit, dispatch }, { forceUpdate }) {
      // Initialize variables
      let startDate = new Date().toLocaleString("pt");
      let synccounter = 1;
      let nrSyncEntities = 0;

      // The following variables will be used to continue an ongoing synchronization
      let savedMaxsynccounter;
      let actualMaxsynccounter;
      let page = 1;

      //Open database and make it start functioning.
      let db = getLocalDataBase();
      db.open();

      //Use Dexie.spawn() or Dexie.async() to enable a synchronous-like programming style.
      var spawn = Dexie.spawn;
      // Interact With Database
      spawn(function* () {
        // Let's query the db
        //Getting the last information stored on the Entities table, to the Payment Method's Entity
        if (forceUpdate === 0) {
          var entityInfo = yield db.entities.where("entity").equals("paymentMethod").toArray();
          let savedPage;
          if (entityInfo.length > 0) {
            synccounter = entityInfo.map((f) => f.synccounter)[0];
            savedMaxsynccounter = entityInfo.map((f) => f.maxsynccounter)[0];
            savedPage = entityInfo.map((f) => f.page)[0];
          }

          // If there is an existing synchronization not finished
          if (savedPage) {
            // Fetch the actual maxsynccounter
            actualMaxsynccounter = yield paymentTypesService.getMaxSynccounter(synccounter);

            // If the actual max synccounter is different from the one when we started the synchronization
            // We need to restart the process otherwise we may lose changes
            if (savedMaxsynccounter && actualMaxsynccounter && savedMaxsynccounter === actualMaxsynccounter) {
              page = savedPage;
            }
          }
        }
        //The "put" adds a new object or updates an existing object.
        //  I've chosen to use it instead of the "update", because the update only updates an existing object.
        //  Therefore, using the "update" it would have been necessary to verify if the "update" returned 1 and if not
        //  performing an insert.
        db.entities.put({
          entity: "paymentMethod",
          startDate: new Date().toISOString(),
          endDate: null,
          nrSyncEntities: null,
          synccounter: synccounter,
        });

        //Iterating over the retrieved Payment Methods:
        //1 - building the dictionary which will add the Payment Methods to the database
        //2 - obtaining the maximum synccounter of the retrieved Payment Methods
        let maxSynccounter = synccounter || 0;
        let bulkPaymentTypes = [];
        let response;
        try {
          do {
            bulkPaymentTypes = [];

            // Accessing the backend API to get all the Payment Types which match with the identified synccounter
            response = yield paymentTypesService.getPage(synccounter, page);
            const result = response.results;

            for (let i = 0; i < result.length; i++) {
              bulkPaymentTypes.push({
                id: result[i]["TenderId"],
                description: result[i]["TenderName"],
                status: result[i]["Status"],
                synccounter: result[i]["synccounter"],
                data: result[i],
              });

              if (result[i].synccounter > maxSynccounter) {
                maxSynccounter = result[i].synccounter;
              }
            }

            //1 - Adding all given objects to the store.
            //2 - updating the Entities table, setting the new synccounter for the Entity Payment Methods and setting the new
            // last sync date
            if (bulkPaymentTypes.length > 0) {
              nrSyncEntities += bulkPaymentTypes.length;
              yield db.paymentMethods
                //  If an object with the same primary key already exists, it will be replaced with the given object.
                //  If it does not exist, it will be added.
                .bulkPut(bulkPaymentTypes)
                .then(async function () {
                  // If this the last page, we don't need to save the progress
                  // Eventually can avoid an unnecessary maxsynccounter request
                  if (!response.next) return;

                  if (!actualMaxsynccounter)
                    actualMaxsynccounter = await paymentTypesService.getMaxSynccounter(synccounter);

                  db.entities.put({
                    entity: "paymentMethod",
                    startDate: startDate,
                    endDate: new Date().toLocaleString("pt"),
                    nrSyncEntities: nrSyncEntities,
                    synccounter: synccounter,
                    maxsynccounter: actualMaxsynccounter,
                    page: page,
                  });
                })
                // eslint-disable-next-line
                .catch(Dexie.BulkError, function (e) {
                  // Explicitly catching the bulkAdd() operation makes those successful
                  // additions commit despite that there were errors.
                });

              page++;
            }
          } while (response.next && page < 2000);

          // Only save the maxsynccounter after all the pages are fetched
          db.entities.put({
            entity: "paymentMethod",
            startDate: startDate,
            endDate: new Date().toLocaleString("pt"),
            nrSyncEntities: nrSyncEntities,
            synccounter: maxSynccounter,
          });
        } catch (err) {
          // To avoid unnecessary requests, it may not be calculated yet
          if (!actualMaxsynccounter) actualMaxsynccounter = yield paymentTypesService.getMaxSynccounter(synccounter);

          // Retry logic failed
          db.entities.put({
            entity: "paymentMethod",
            startDate: startDate,
            endDate: new Date().toLocaleString("pt"),
            nrSyncEntities: nrSyncEntities,
            synccounter: synccounter,
            maxsynccounter: actualMaxsynccounter,
            page: page,
          });
        }

        // eslint-disable-next-line
      }).catch(function (err) {
        // Catch any error event or exception
        console.error(err.stack || err);
      });
    },
  },
  getters: {
    get: () => (id) => {
      let db = getLocalDataBase();

      return db.paymentMethods.where("id").equals(id).toArray();
    },
    async getAll() {
      let db = getLocalDataBase();

      let resultPaymentMethods = [];

      //1st filtering the active Payment Methods
      let activePaymentMethods = db.paymentMethods.where("status").equals(1);

      activePaymentMethods = await activePaymentMethods.toArray();

      //For each active Payment Method, checking if it enables giving Change to the Customer.
      for (var i = 0; i < activePaymentMethods.length; i++) {
        if (String(activePaymentMethods[i].data["TenderUse"]).includes(0)) {
          resultPaymentMethods.push(activePaymentMethods[i]);
        }
      }

      return resultPaymentMethods;
    },
    /**
     * Identifying the active Payment Methods which allow giving Change to the Customer.
     * @returns {Promise<[]>}
     */
    async getTypeCash() {
      let db = getLocalDataBase();

      let resultPaymentMethods = [];

      //1st filtering the active Payment Methods
      let activePaymentMethods = db.paymentMethods.where("status").equals(1);

      activePaymentMethods = await activePaymentMethods.toArray();

      //For each active Payment Method, checking if it enables giving Change to the Customer.
      for (var i = 0; i < activePaymentMethods.length; i++) {
        if (activePaymentMethods[i].data["TenderType"] === 1) {
          resultPaymentMethods.push(activePaymentMethods[i]);
        }
      }

      return resultPaymentMethods;
    },
    /**
     * Identifying the active Payment Methods which allow giving Change to the Customer.
     * @returns {Promise<[]>}
     */
    async getUseInReturnDocument() {
      let db = getLocalDataBase();

      let resultPaymentMethods = [];

      //1st filtering the active Payment Methods
      let activePaymentMethods = db.paymentMethods.where("status").equals(1);

      activePaymentMethods = await activePaymentMethods.toArray();

      //For each active Payment Method, checking if it enables giving Change to the Customer.
      for (var i = 0; i < activePaymentMethods.length; i++) {
        if (String(activePaymentMethods[i].data["TenderUse"]).includes(3)) {
          resultPaymentMethods.push(activePaymentMethods[i]);
        }
      }

      return resultPaymentMethods;
    },
  },
};
