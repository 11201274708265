import Vue from "vue";
import { apiFetch, getApiBaseStore } from "@/_helpers";

export const tenantsService = {
  checkTenant,
  getSynccounter,
};

function checkTenant(name, store, type) {
  let url;
  let uri;
  if (name === "dev") {
    url = "http:\\\\127.0.0.1:8000";
  } else {
    url = "https:\\\\" + name + ".storesace.com";
  }
  uri = "dimas/api/core/info/?store=" + store;

  let requestOptions = {};
  Vue.$log.debug("API URL:", url);
  Vue.$log.debug("API URI:", uri);
  requestOptions = {
    method: "GET",
    baseURL: url,
    url: uri,
  };
  Vue.$log.debug(type);

  return Vue.axios(requestOptions);
}

async function getSynccounter() {
  try {
    let fetchPath = "core/info/?store=" + getApiBaseStore();
    let result = await apiFetch(fetchPath);
    return result.synccounter;
  } catch (error) {
    console.error("Error fetching synccounter:", error);
  }
}
