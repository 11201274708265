import { datadogRum } from "@datadog/browser-rum";

if (process.env.NODE_ENV === "production") {
  datadogRum.init({
    applicationId: "4cc61357-d561-4e2c-94d4-3299fa432ab8",
    clientToken: "pubb05c4730152ee6948f65f20f9ff66246",
    site: "datadoghq.com",
    service: "easysalespos",
    env: "prod",
    version: "1.12.09",
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
  });

  datadogRum.startSessionReplayRecording();
}
