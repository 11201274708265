import { docTypesServices } from "@/services";
import { getLocalDataBase } from "@/_helpers";
import Dexie from "dexie";

export const docTypes = {
  namespaced: true,
  state: {
    activeForSale: localStorage.getItem("activeDocTypeSale") || "FR",
    activeForReturn: localStorage.getItem("activeDocTypeReturn") || "NC",
  },
  actions: {
    // eslint-disable-next-line
    setConfiguration({ commit, dispatch }, { forceUpdate }) {
      // Initialize variables
      let startDate = new Date().toLocaleString("pt");
      let synccounter = 1;
      let nrSyncEntities = 0;

      // The following variables will be used to continue an ongoing synchronization
      let savedMaxsynccounter;
      let actualMaxsynccounter;
      let page = 1;

      //Open database and make it start functioning.
      let db = getLocalDataBase();
      db.open();

      //Use Dexie.spawn() or Dexie.async() to enable a synchronous-like programming style.
      var spawn = Dexie.spawn;
      // Interact With Database
      spawn(function* () {
        // Let's query the db
        //Getting the last information stored on the Entities table, to the Payment Method's Entity
        if (forceUpdate === 0) {
          const entityInfo = yield db.entities.where("entity").equals("docTypes").toArray();
          let savedPage;
          if (entityInfo.length > 0) {
            synccounter = entityInfo.map((f) => f.synccounter)[0];
            savedMaxsynccounter = entityInfo.map((f) => f.maxsynccounter)[0];
            savedPage = entityInfo.map((f) => f.page)[0];
          }

          // If there is an existing synchronization not finished
          if (savedPage) {
            // Fetch the actual maxsynccounter
            actualMaxsynccounter = yield docTypesServices.getMaxSynccounter(synccounter);

            // If the actual max synccounter is different from the one when we started the synchronization
            // We need to restart the process otherwise we may lose changes
            if (savedMaxsynccounter && actualMaxsynccounter && savedMaxsynccounter === actualMaxsynccounter) {
              page = savedPage;
            }
          }
        }

        //The "put" adds a new object or updates an existing object.
        //  I've chosen to use it instead of the "update", because the update only updates an existing object.
        //  Therefore, using the "update" it would have been necessary to verify if the "update" returned 1 and if not
        //  performing an insert.
        db.entities.put({
          entity: "docTypes",
          startDate: new Date().toISOString(),
          endDate: null,
          nrSyncEntities: null,
          synccounter: synccounter,
        });

        //Iterating over the retrieved DocTypes:
        //1 - building the dictionary which will add the DocTypes to the database
        //2 - obtaining the maximum synccounter of the retrieved DocTypes
        let maxSynccounter = synccounter || 0;
        let bulkDocTypes = [];
        let response;
        try {
          do {
            bulkDocTypes = [];

            // Accessing the backend API to get all the DocTypes which match with the identified synccounter
            response = yield docTypesServices.getPage(synccounter, page);
            const result = response.results;

            for (let i = 0; i < result.length; i++) {
              bulkDocTypes.push({
                id: result[i]["Id"],
                description: result[i]["Description"],
                code: result[i]["Code"],
                nature: result[i]["Nature"],
                synccounter: result[i]["synccounter"],
                print_page_size: result[i]["PrintPageSize"],
                data: result[i],
              });

              if (result[i].synccounter > maxSynccounter) {
                maxSynccounter = result[i].synccounter;
              }
            }

            //1 - Adding all given objects to the store.
            //2 - updating the Entities table, setting the new synccounter for the Entity Countries and setting the new
            // last sync date
            if (bulkDocTypes.length > 0) {
              nrSyncEntities += bulkDocTypes.length;
              yield db.docTypes
                //  If an object with the same primary key already exists, it will be replaced with the given object.
                //  If it does not exist, it will be added.
                .bulkPut(bulkDocTypes)
                .then(async function () {
                  // If this the last page, we don't need to save the progress
                  // Eventually can avoid an unnecessary maxsynccounter request
                  if (!response.next) return;

                  if (!actualMaxsynccounter)
                    actualMaxsynccounter = await docTypesServices.getMaxSynccounter(synccounter);

                  db.entities.put({
                    entity: "docTypes",
                    startDate: startDate,
                    endDate: new Date().toLocaleString("pt"),
                    nrSyncEntities: nrSyncEntities,
                    synccounter: synccounter,
                    maxsynccounter: actualMaxsynccounter,
                    page: page,
                  });
                })
                // eslint-disable-next-line
                .catch(Dexie.BulkError, function (e) {
                  // Explicitly catching the bulkAdd() operation makes those successful
                  // additions commit despite that there were errors.
                });

              page++;
            }
          } while (response.next && page < 2000);

          // Only save the maxsynccounter after all the pages are fetched
          db.entities.put({
            entity: "docTypes",
            startDate: startDate,
            endDate: new Date().toLocaleString("pt"),
            nrSyncEntities: nrSyncEntities,
            synccounter: maxSynccounter,
          });
        } catch (err) {
          // To avoid unnecessary requests, it may not be calculated yet
          if (!actualMaxsynccounter) actualMaxsynccounter = yield docTypesServices.getMaxSynccounter(synccounter);

          // Retry logic failed
          db.entities.put({
            entity: "docTypes",
            startDate: startDate,
            endDate: new Date().toLocaleString("pt"),
            nrSyncEntities: nrSyncEntities,
            synccounter: synccounter,
            maxsynccounter: actualMaxsynccounter,
            page: page,
          });
        }

        // Check if doc types saved in local storage are valid
        let descriptions = yield db.docTypes.toArray().then((l) => l.map((d) => d.code));
        if (!descriptions.includes(localStorage.getItem("activeDocTypeSale"))) {
          commit("setActiveSale", "FR");
        }
        if (!descriptions.includes(localStorage.getItem("activeDocTypeReturn"))) {
          commit("setActiveReturn", "NC");
        }

        // eslint-disable-next-line
      }).catch(function (err) {
        // Catch any error event or exception
        console.error(err.stack || err);
      });
    },
    setActiveSale({ commit }, payload) {
      commit("setActiveSale", payload);
    },
    setActiveReturn({ commit }, payload) {
      commit("setActiveReturn", payload);
    },
  },
  mutations: {
    setActiveSale: (state, value) => {
      state.activeForSale = value;
      localStorage.setItem("activeDocTypeSale", value);
    },
    setActiveReturn: (state, value) => {
      state.activeForReturn = value;
      localStorage.setItem("activeDocTypeReturn", value);
    },
  },
  getters: {
    get: () => (id) => {
      let db = getLocalDataBase();
      return db.docTypes.where("id").equals(id).toArray();
    },

    getActiveSale: (state) => () => {
      return state.activeForSale;
    },

    getActiveReturn: (state) => () => {
      return state.activeForReturn;
    },

    getAll: () => {
      let db = getLocalDataBase();
      return db.docTypes.toArray();
    },

    getPrintPageSize: () => async (code) => {
      let db = getLocalDataBase();
      let docTypes = await db.docTypes.where("code").equals(code).toArray();
      return docTypes.length === 1 ? docTypes[0].print_page_size : null;
    },
  },
};
