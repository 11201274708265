import { salesmenService } from "@/services";
import { getLocalDataBase, PERM_ADMINISTRATION, PERM_REPORTS, verbosePermissions } from "@/_helpers";
import Dexie from "dexie";
import { store } from "@/store/index";

export const salesmen = {
  namespaced: true,
  state: {
    //The identification of the Salesman selected on the interface to perform operations such as:
    // Sales, Open/Close Cashdrawers, access the Sales History and access the Administration menu
    //The Salesman is identified using its complete register obtained from the IndexedDB
    selectedSalesman: undefined,
    //A list with the permissions assigned to the Salesman: [101, 102, 103] for example.
    //This list is checked during the various operations the Salesman tries to perform.
    selectedSalesmanLevels: undefined,
    //A flag to identify if the dialog to select a Salesman should be displayed
    displaySelectSalesman: false,
    //Depending on where the user was prompted with the dialog to select the Salesman, after selecting it, the
    // user must be redirected to a specific router. This variable stores the router identified by the action
    // setDisplaySelectSalesman
    redirectPostSelectSalesman: undefined,
    redirectPostSelectSalesmanParameters: undefined,
  },
  mutations: {
    setSelectedSalesman: (state, salesman) => {
      state.selectedSalesman = salesman;
    },
    setSelectedSalesmanLevels: (state, salesmanLevels) => {
      state.selectedSalesmanLevels = salesmanLevels;
    },
    setDisplaySelectSalesman: (state, status) => {
      state.displaySelectSalesman = status;
    },
    setRedirectPostSelectSalesman: (state, { redirectPostSelectSalesman, redirectPostSelectSalesmanParameters }) => {
      state.redirectPostSelectSalesman = redirectPostSelectSalesman;
      state.redirectPostSelectSalesmanParameters = redirectPostSelectSalesmanParameters;
    },
    // eslint-disable-next-line
    setSalesmanSessionOpen: (state, { salesmanId, date }) => {
      let db = getLocalDataBase();

      db.salesmen.update(salesmanId, { sessionStatus: 1 });
      db.salesmen.update(salesmanId, { sessionDate: date });
    },
    // eslint-disable-next-line
    setSalesmanSessionClose: (state, { salesmanId, date }) => {
      let db = getLocalDataBase();

      db.salesmen.update(salesmanId, { sessionStatus: 0 });
      db.salesmen.update(salesmanId, { sessionDate: date });
    },
  },
  actions: {
    // eslint-disable-next-line
    setConfiguration({ commit, dispatch }, { forceUpdate }) {
      // Initialize variables
      let startDate = new Date().toLocaleString("pt");
      let synccounter = 1;
      let nrSyncEntities = 0;

      // The following variables will be used to continue an ongoing synchronization
      let savedMaxsynccounter;
      let actualMaxsynccounter;
      let page = 1;

      //Open database and make it start functioning.
      let db = getLocalDataBase();
      db.open();

      //Use Dexie.spawn() or Dexie.async() to enable a synchronous-like programming style.
      var spawn = Dexie.spawn;
      // Interact With Database
      spawn(function* () {
        // Let's query the db
        //Getting the last information stored on the Entities table, to the Salesman's Entity
        if (forceUpdate === 0) {
          const entityInfo = yield db.entities.where("entity").equals("salesman").toArray();
          let savedPage;
          if (entityInfo.length > 0) {
            synccounter = entityInfo.map((f) => f.synccounter)[0];
            savedMaxsynccounter = entityInfo.map((f) => f.maxsynccounter)[0];
            savedPage = entityInfo.map((f) => f.page)[0];
          }

          // If there is an existing synchronization not finished
          if (savedPage) {
            // Fetch the actual maxsynccounter
            actualMaxsynccounter = yield salesmenService.getMaxSynccounter(synccounter);

            // If the actual max synccounter is different from the one when we started the synchronization
            // We need to restart the process otherwise we may lose changes
            if (savedMaxsynccounter && actualMaxsynccounter && savedMaxsynccounter === actualMaxsynccounter) {
              page = savedPage;
            }
          }
        }
        //The "put" adds a new object or updates an existing object.
        //  I've chosen to use it instead of the "update", because the update only updates an existing object.
        //  Therefore, using the "update" it would have been necessary to verify if the "update" returned 1 and if not
        //  performing an insert.
        db.entities.put({
          entity: "salesman",
          startDate: startDate,
          endDate: null,
          nrSyncEntities: null,
          synccounter: synccounter,
        });

        //Iterating over the retrieved Salesman:
        //1 - building the dictionary which will add the Salesman to the database
        //2 - obtaining the maximum synccounter of the retrieved Salesman
        let maxSynccounter = synccounter || 0;
        let bulkSalesmen = [];
        let response;
        try {
          do {
            bulkSalesmen = [];

            //Accessing the backend API to get all the Salesman which match with the identified synccounter
            response = yield salesmenService.getPage(synccounter, page);
            let result = response.results;

            for (let i = 0; i < result.length; i++) {
              bulkSalesmen.push({
                id: result[i]["SalesmanId"],
                name: result[i]["SalesmanName"],
                status: result[i]["Status"],
                synccounter: result[i]["synccounter"],
                sessionStatus: result[i]["SalesmanSessionStatus"],
                sessionDate: result[i]["SalesmanSessionDate"],
                data: result[i],
              });

              if (result[i].synccounter > maxSynccounter) {
                maxSynccounter = result[i].synccounter;
              }
            }

            //1 - Adding all given objects to the store.
            //2 - updating the Entities table, setting the new synccounter for the Entity Salesman and setting the new
            // last sync date
            if (bulkSalesmen.length > 0) {
              nrSyncEntities += bulkSalesmen.length;
              yield db.salesmen
                //  If an object with the same primary key already exists, it will be replaced with the given object.
                //  If it does not exist, it will be added.
                .bulkPut(bulkSalesmen)
                .then(async function () {
                  // If this the last page, we don't need to save the progress
                  // Eventually can avoid an unnecessary maxsynccounter request
                  if (!response.next) return;

                  if (!actualMaxsynccounter)
                    actualMaxsynccounter = await salesmenService.getMaxSynccounter(synccounter);

                  db.entities.put({
                    entity: "salesman",
                    startDate: startDate,
                    endDate: new Date().toLocaleString("pt"),
                    nrSyncEntities: nrSyncEntities,
                    synccounter: synccounter,
                    maxsynccounter: actualMaxsynccounter,
                    page: page,
                  });
                })
                // eslint-disable-next-line
                .catch(Dexie.BulkError, function (e) {
                  // Explicitly catching the bulkAdd() operation makes those successful
                  // additions commit despite that there were errors.
                });
              page++;
            }
          } while (response.next && page < 2000);

          // Only save the maxsynccounter after all the pages are fetched
          db.entities.put({
            entity: "salesman",
            startDate: startDate,
            endDate: new Date().toLocaleString("pt"),
            nrSyncEntities: nrSyncEntities,
            synccounter: maxSynccounter,
          });
        } catch (err) {
          // To avoid unnecessary requests, it may not be calculated yet
          if (!actualMaxsynccounter) actualMaxsynccounter = yield salesmenService.getMaxSynccounter(synccounter);

          // Retry logic failed
          db.entities.put({
            entity: "salesman",
            startDate: startDate,
            endDate: new Date().toLocaleString("pt"),
            nrSyncEntities: nrSyncEntities,
            synccounter: synccounter,
            maxsynccounter: actualMaxsynccounter,
            page: page,
          });
        }

        // eslint-disable-next-line
      }).catch(function (err) {
        // Catch any error event or exception
        console.error(err.stack || err);
      });
    },
    // eslint-disable-next-line
    setConfigurationPermissionGroup({ commit, dispatch }, { forceUpdate }) {
      // Initialize variables
      let startDate = new Date().toLocaleString("pt");
      let synccounter = 1;
      let nrSyncEntities = 0;

      // The following variables will be used to continue an ongoing synchronization
      let savedMaxsynccounter;
      let actualMaxsynccounter;
      let page = 1;

      //Open database and make it start functioning.
      let db = getLocalDataBase();
      db.open();

      //Use Dexie.spawn() or Dexie.async() to enable a synchronous-like programming style.
      var spawn = Dexie.spawn;
      // Interact With Database
      spawn(function* () {
        // Let's query the db
        //Getting the last information stored on the Entities table, to the Permission's Entity
        if (forceUpdate === 0) {
          const entityInfo = yield db.entities.where("entity").equals("permissionsGroup").toArray();
          let savedPage;
          if (entityInfo.length > 0) {
            synccounter = entityInfo.map((f) => f.synccounter)[0];
            savedMaxsynccounter = entityInfo.map((f) => f.maxsynccounter)[0];
            savedPage = entityInfo.map((f) => f.page)[0];
          }

          // If there is an existing synchronization not finished
          if (savedPage) {
            // Fetch the actual maxsynccounter
            actualMaxsynccounter = yield salesmenService.getSalesmenLevelsMaxSynccounter(synccounter);

            // If the actual max synccounter is different from the one when we started the synchronization
            // We need to restart the process otherwise we may lose changes
            if (savedMaxsynccounter && actualMaxsynccounter && savedMaxsynccounter === actualMaxsynccounter) {
              page = savedPage;
            }
          }
        }
        //The "put" adds a new object or updates an existing object.
        //  I've chosen to use it instead of the "update", because the update only updates an existing object.
        //  Therefore, using the "update" it would have been necessary to verify if the "update" returned 1 and if not
        //  performing an insert.
        db.entities.put({
          entity: "permissionsGroup",
          startDate: startDate,
          endDate: null,
          nrSyncEntities: null,
          synccounter: synccounter,
        });

        //Iterating over the retrieved Salesman:
        //1 - building the dictionary which will add the Salesman to the database
        //2 - obtaining the maximum synccounter of the retrieved Salesman
        let maxSynccounter = synccounter || 0;
        let bulkPermissionGroups = [];
        let response;
        try {
          do {
            bulkPermissionGroups = [];

            // Accessing the backend API to get all the Salesmen which match with the identified synccounter
            response = yield salesmenService.getSalesmenLevelsPage(synccounter, page);
            const result = response.results;

            for (let i = 0; i < result.length; i++) {
              bulkPermissionGroups.push({
                id: result[i]["Id"],
                description: result[i]["AppGroupDescription"],
                status: result[i]["Status"],
                synccounter: result[i]["synccounter"],
                activePermissions: result[i]["Permissions"],
              });

              if (result[i].synccounter > maxSynccounter) {
                maxSynccounter = result[i].synccounter;
              }
            }

            //1 - Adding all given objects to the store.
            //2 - updating the Entities table, setting the new synccounter for the Entity Salesman and setting the new
            // last sync date
            if (bulkPermissionGroups.length > 0) {
              nrSyncEntities += bulkPermissionGroups.length;
              yield db.permissionsGroup
                //  If an object with the same primary key already exists, it will be replaced with the given object.
                //  If it does not exist, it will be added.
                .bulkPut(bulkPermissionGroups)
                .then(async function () {
                  // If this the last page, we don't need to save the progress
                  // Eventually can avoid an unnecessary maxsynccounter request
                  if (!response.next) return;

                  if (!actualMaxsynccounter)
                    actualMaxsynccounter = await salesmenService.getSalesmenLevelsMaxSynccounter(synccounter);

                  db.entities.put({
                    entity: "permissionsGroup",
                    startDate: startDate,
                    endDate: new Date().toLocaleString("pt"),
                    nrSyncEntities: nrSyncEntities,
                    synccounter: synccounter,
                    maxsynccounter: actualMaxsynccounter,
                    page: page,
                  });
                })
                // eslint-disable-next-line
                .catch(Dexie.BulkError, function (e) {
                  // Explicitly catching the bulkAdd() operation makes those successful
                  // additions commit despite that there were errors.
                });
              page++;
            }
          } while (response.next && page < 2000);

          // Only save the maxsynccounter after all the pages are fetched
          db.entities.put({
            entity: "permissionsGroup",
            startDate: startDate,
            endDate: new Date().toLocaleString("pt"),
            nrSyncEntities: nrSyncEntities,
            synccounter: maxSynccounter,
          });
        } catch (err) {
          // To avoid unnecessary requests, it may not be calculated yet
          if (!actualMaxsynccounter)
            actualMaxsynccounter = yield salesmenService.getSalesmenLevelsMaxSynccounter(synccounter);

          // Retry logic failed
          db.entities.put({
            entity: "permissionsGroup",
            startDate: startDate,
            endDate: new Date().toLocaleString("pt"),
            nrSyncEntities: nrSyncEntities,
            synccounter: synccounter,
            maxsynccounter: actualMaxsynccounter,
            page: page,
          });
        }

        // eslint-disable-next-line
      }).catch(function (err) {
        // Catch any error event or exception
        console.error(err.stack || err);
      });
    },
    getAll: () => {
      let db = getLocalDataBase();

      return db.salesmen.where("status").equals(1).sortBy("name");
    },
    get: (params, id) => {
      let db = getLocalDataBase();

      return db.salesmen.where("id").equals(id).toArray();
    },
    getAllWithOpenSession: () => {
      let db = getLocalDataBase();

      return db.salesmen.where({ sessionStatus: 1, status: 1 }).sortBy("name");
    },

    setSelectedSalesman: async ({ commit }, salesman) => {
      if (salesman) {
        // Set salesman
        commit("setSelectedSalesman", salesman);
        // Set salesman levels
        let db = getLocalDataBase();

        let levels = await db.permissionsGroup.where("id").equals(salesman.data["PermissionsGroupId"]).toArray();
        commit("setSelectedSalesmanLevels", levels[0]["activePermissions"]);
      }
    },
    setDisplaySelectSalesman: (
      { commit },
      { status, redirectPostSelectSalesman = "catalog", redirectPostSelectSalesmanParameters }
    ) => {
      //@modified ana.castro 2022.12.05 SAFO-47
      commit("setDisplaySelectSalesman", status);
      commit("setRedirectPostSelectSalesman", { redirectPostSelectSalesman, redirectPostSelectSalesmanParameters });
    },
    setSalesmanSessionOpen: ({ commit }, { salesmanId, date }) => {
      commit("setSalesmanSessionOpen", { salesmanId, date });
    },
    setSalesmanSessionClose: ({ commit }, { salesmanId, date }) => {
      commit("setSalesmanSessionClose", { salesmanId, date });
    },

    /**
     *
     * @param commit
     * @param getters
     * @param to
     */
    validateRouterIdentifySalesman: ({ commit }, { to }) => {
      //
      let redirectPostSelectSalesman = undefined;
      let redirectPostSelectSalesmanParameters = to.params;

      commit("setDisplaySelectSalesman", true);

      if (to.meta.context === "reports") {
        redirectPostSelectSalesman = "reports";
      } else if (to.meta.context === "cashDrawers") {
        redirectPostSelectSalesman = "cash-drawers-management";
      } else if (to.meta.context === "administration-configuration") {
        redirectPostSelectSalesman = "administration";
      } else if (to.meta.context === "salesHistory") {
        redirectPostSelectSalesman = "sales-history";
      } else {
        redirectPostSelectSalesman = "catalog";
      }

      commit("setRedirectPostSelectSalesman", { redirectPostSelectSalesman, redirectPostSelectSalesmanParameters });
    },
  },
  getters: {
    isSalesmanIdentified: (state) => {
      return state.selectedSalesman !== undefined;
    },
    /**
     * In this function, there's an identification of the Routers which can only be accessed if a Salesman is identified.
     * This rule has three variations:
     * 1 - it might exist a Global Configuration (configs store) which indicates that once a Salesman is
     *  identified, it is not necessary to identify the Salesman even the user is changing the working context
     * 2 - on some Routes if the Salesman is identified, it is not necessary to identify the Salesman everytime
     *  the user tries to access the Route
     * 3 - on the other hand, other Routes require the identification of a Salesman, even if the Salesman was already
     * identified
     *
     * @param state
     * @returns {(function({from: *, to: *}): (boolean))|*}
     */
    isNecessaryToIdentifySalesman: (state) => ({ from, to }) => {
      //No validations performed when the destination is in one of these two contexts
      if (to.name === "home" || to.name === "login" || to.name === "salesmen-sessions") {
        return false;
      }

      //Validating the Store Configuration which indicates if a Salesman should be selected prior to perform some actions
      let isMandatoryToSelectSalesman = store.getters["configs/selectSalesman"];

      //It is not mandatory to select a Salesman, but we have to assure that a Salesman is identified
      if (!isMandatoryToSelectSalesman) {
        if (!store.getters["salesmen/isSalesmanIdentified"]) {
          return true;
        } else {
          return false;
        }
      }
      //The global configuration indicates that it is necessary to identify a Salesman when changing between some contexts
      else {
        //Contexts where it is mandatory to identify a Salesman, even if a Salesman is already selected
        let routerMandatorySelectSalesman = ["salesHistory", "reports", "cashDrawers", "administration-configuration"];
        //Contexts where it is mandatory to identify a Salesman, only if a Salesman is not yet selected
        let routerMandatorySelectSalesmanIfNotExists = ["sale", "sale-return"];

        if ((!from || !to.params.selectedSalesman) && routerMandatorySelectSalesman.includes(to.meta.context)) {
          return true;
        } else if (
          (!to.params.selectedSalesman &&
            routerMandatorySelectSalesmanIfNotExists.includes(to.meta.context) &&
            state.selectedSalesman === undefined) ||
          (!to.params.selectedSalesman &&
            routerMandatorySelectSalesmanIfNotExists.includes(to.meta.context) &&
            !routerMandatorySelectSalesmanIfNotExists.includes(from.meta.context))
        ) {
          return true;
        }
      }

      return false;
    },
    getSelectedSalesman: (state) => {
      return state.selectedSalesman;
    },
    isToDisplaySelectSalesman: (state) => {
      return state.displaySelectSalesman;
    },
    getSelectedSalesmanLevels: (state) => {
      return state.selectedSalesmanLevels;
    },
    getRedirectPostSelectSalesman: (state) => {
      return state.redirectPostSelectSalesman;
    },
    getRedirectPostSelectSalesmanParameters: (state) => {
      return state.redirectPostSelectSalesmanParameters;
    },
    /**
     * Everytime a Salesman is identified, the Salesman permissions are stored on the "selectedSalesmanLevels" variable.
     * This function evaluates if the selected Salesman has access to a given permission, by analysing the permissions
     *  previously identified.
     *
     * @param state
     * @returns {(function(*): (boolean))|*}
     */
    validateSelectedSalesmanAccessToPermission: (state) => (permission) => {
      let selectedSalesmanLevels = state.selectedSalesmanLevels;

      if (selectedSalesmanLevels && selectedSalesmanLevels.indexOf(permission) < 0) {
        return false;
      }

      return true;
    },
    /**
     * There are some Routes which require a specific permission in order to the Salesman access it.
     * This function validates if the selected Salesman has the necessary permissions.
     * In case, the Salesman does not have the required permissions, this function returns a boolean value, but also
     *   a string with the name of the necessary permission.
     *
     * @returns {(function(*): ({value: boolean, verbose: *}))|*}
     */
    hasSelectSalesmanAccessToRouter: () => (to) => {
      if (
        to.meta.context === "reports" &&
        !store.getters["salesmen/validateSelectedSalesmanAccessToPermission"](PERM_REPORTS)
      ) {
        return { value: false, verbose: verbosePermissions[PERM_REPORTS] };
      } else if (
        to.meta.context === "administration-configuration" &&
        !store.getters["salesmen/validateSelectedSalesmanAccessToPermission"](PERM_ADMINISTRATION)
      ) {
        return { value: false, verbose: verbosePermissions[PERM_ADMINISTRATION] };
      }

      return { value: true, verbose: undefined };
    },
  },
};
